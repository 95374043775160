import React, { useEffect, useState , Suspense} from "react"
import { withRouter, Route, Redirect, Switch } from "react-router-dom"
import PropTypes from "prop-types"

import { Container, Card, CardBody } from "reactstrap"

// add saga fun
import { changeBodyAttribute, manageBodyClass } from "../../store/layout/saga"

//components
import Navbar from "./Navbar"
import Header from "./Header"
import Footer from "./Footer"
import LeftSidebar from "./LeftSidebar"
import UserProfile from "./UserProfile"

import { userRoutes } from "routes"

import ScaleLoader from "react-spinners/ScaleLoader";


const Layout = props => {

  const Loader = () => (
    <Card>
      <CardBody>
        <ScaleLoader height={25} width={15} color={"#E6E7E7"} size={150} />
      </CardBody>
    </Card>
  )
  /*
  document title
  */
  useEffect(() => {
    const title = props.location.pathname
    let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    // set layout width boxed
    changeBodyAttribute("data-sidebar-size", "")
    changeBodyAttribute("data-keep-enlarged", "true")
    manageBodyClass("vertical-collpsed", "add")

    changeBodyAttribute("data-layout-size", "boxed")
    changeBodyAttribute("data-layout-scrollable", false)

    changeBodyAttribute("data-topbar", "light")

    changeBodyAttribute("data-layout", "horizontal")

    document.body.removeAttribute("data-sidebar")
    document.body.removeAttribute("data-sidebar-image")
    document.body.removeAttribute("data-sidebar-size")

    document.title = currentage + " | 直码科技"
  }, [props.location.pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.location.pathname])

  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened)
  }

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          theme="light"
          isMenuOpened={isMenuOpened}
          openLeftMenuCallBack={openMenu}
        />
        <Navbar menuOpen={isMenuOpened} />
        <div className="main-content">
          <div className="page-content">
            <Container fluid>
              <div className="user-center">
                <div className="user-side-bar me-md-3 col-md-auto">
                  <UserProfile />
                  <LeftSidebar />
                </div>

                <div className="user-center-main-content col">
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      {userRoutes.map((route, idx) => (
                        <Route
                          exact
                          key={idx}
                          path={"/user" + route.path}
                          component={route.component}
                        />
                      ))}
                      <Route render={() => <Redirect to="/foo" />} />
                    </Switch>
                  </Suspense>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  changeLayout: PropTypes.func,
  children: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Layout)
