import React from 'react'


const Logout = React. lazy(() => import(/* webpackChunkName: "Logout" */ "../pages/Auth/Logout"))

const Login1 = React. lazy(() => import(/* webpackChunkName: "Login1" */ "../pages/Auth/Login1"))
const Login2 = React. lazy(() => import(/* webpackChunkName: "Login2" */ "../pages/Auth/Login2"))
const Register1 = React. lazy(() => import(/* webpackChunkName: "Register1" */ "../pages/Auth/Register1"))
const Register2 = React. lazy(() => import(/* webpackChunkName: "Register2" */ "../pages/Auth/Register2"))
const Recoverpw = React. lazy(() => import(/* webpackChunkName: "Recoverpw" */ "../pages/Auth/Recoverpw"))
const Recoverpw2 = React. lazy(() => import(/* webpackChunkName: "Recoverpw2" */ "../pages/Auth/Recoverpw2"))
const ForgetPwd1 = React. lazy(() => import(/* webpackChunkName: "ForgetPwd1" */ "../pages/Auth/ForgetPassword1"))
const ForgetPwd2 = React. lazy(() => import(/* webpackChunkName: "ForgetPwd2" */ "../pages/Auth/ForgetPassword2"))
const LockScreen = React. lazy(() => import(/* webpackChunkName: "LockScreen" */ "../pages/Auth/auth-lock-screen"))
const LockScreen2 = React. lazy(() => import(/* webpackChunkName: "LockScreen2" */ "../pages/Auth/auth-lock-screen-2"))
const ConfirmMail = React. lazy(() => import(/* webpackChunkName: "ConfirmMail" */ "../pages/Auth/page-confirm-mail"))
const ConfirmMail2 = React. lazy(() => import(/* webpackChunkName: "ConfirmMail2" */ "../pages/Auth/page-confirm-mail-2"))
const EmailVerification = React. lazy(() => import(/* webpackChunkName: "EmailVerification" */ "../pages/Auth/auth-email-verification"))
const EmailVerification2 = React. lazy(() => import(/* webpackChunkName: "EmailVerification2" */ "../pages/Auth/auth-email-verification-2"))
const TwostepVerification = React. lazy(() => import(/* webpackChunkName: "TwostepVerification" */ "../pages/Auth/auth-two-step-verification"))
const TwostepVerification2 = React. lazy(() => import(/* webpackChunkName: "TwostepVerification2" */ "../pages/Auth/auth-two-step-verification-2"))

export const authRoutes = [
  { path: "/login", component: Login1 },
  { path: "/register", component: Register1 },
  { path: "/forgot-password", component: ForgetPwd1 },
  { path: "/recoverpw", component: Recoverpw },
  { path: "/confirm-mail", component: ConfirmMail },
  { path: "/lock-screen", component: LockScreen },
  { path: "/email-verification", component: EmailVerification },
  { path: "/two-step-verification", component: TwostepVerification },

  { path: "/login2", component: Login2 },
  { path: "/register2", component: Register2 },
  { path: "/forgot-password2", component: ForgetPwd2 },
  { path: "/recoverpw2", component: Recoverpw2 },
  { path: "/confirm-mail2", component: ConfirmMail2 },
  { path: "/lock-screen2", component: LockScreen2 },
  { path: "/email-verification2", component: EmailVerification2 },
  { path: "/two-step-verification2", component: TwostepVerification2 },

  { path: "/logout", component: Logout }

]
