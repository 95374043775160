import React from 'react'

const Home = React. lazy(() => import(/* webpackChunkName: "Home" */ "../pages/Public/Home"))
const Pages404 = React. lazy(() => import(/* webpackChunkName: "Pages404" */ "../pages/Public/Utility/pages-404"))

export const homeRoutes = [
  // { path: "/", component: Home },
  { path: "*", component: Pages404 },
]

export const publicRoutes = [


  // { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },

  // { path: "/pages-500", component: Pages500 },


]
