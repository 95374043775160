/**
 * App Config File
 */
import React from 'react'
import logoDark from "../assets/images/logo-dark.png"
import logoLight from "../assets/images/logo-light.png"
import logoSmLight from "../assets/images/logo-sm-light.png"
import logoSmDark from "../assets/images/logo-sm-dark.png"

const AppConfig = {
  logo: {
    logoDark,
    logoLight,
    logoSmLight,
    logoSmDark,
  },
  copyRights: {
    shortInfo: "直码科技版权所有 © 2017-2022",
    shortInfoExtend: <a href="http://beian.miit.gov.cn/" target="_blank" rel="noreferrer">京ICP备18047893号-3</a>,
    shortDes: "[直码科技]立足于让企业数字转型中少走弯路、甚至不走弯路，伴随企业共同成长，为企业的不同阶段提供更合适的数字转型方案。"
  },
}

export default AppConfig
