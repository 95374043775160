import React from "react"

const Orders = React.lazy(() =>
  import(/* webpackChunkName: "Orders" */ "../pages/User/Orders/index")
)
const Wallet = React.lazy(() =>
  import(/* webpackChunkName: "Wallet" */ "../pages/User/Wallet/index")
)
const Earnings = React.lazy(() =>
  import(/* webpackChunkName: "Earnings" */ "../pages/User/Earnings/index")
)
const Comments = React.lazy(() =>
  import(/* webpackChunkName: "Comments" */ "../pages/User/Comments/index")
)
const Favourites = React.lazy(() =>
  import(/* webpackChunkName: "Favourites" */ "../pages/User/Favourites/index")
)
const Messages = React.lazy(() =>
  import(/* webpackChunkName: "Messages" */ "../pages/User/Messages/index")
)
const Profile = React.lazy(() =>
  import(/* webpackChunkName: "Profile" */ "../pages/User/Profile/index")
)


export const userRoutes = [
  { path: "/wallet", component: Wallet },
  { path: "/earnings", component: Earnings },
  { path: "/comments", component: Comments },
  { path: "/favourites", component: Favourites },
  { path: "/messages", component: Messages },
  { path: "/profile", component: Profile },
  { path: "/orders", component: Orders },
  { path: "/", component: Orders },
]
