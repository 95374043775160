import axios, { Cancel } from "axios"
import { configure } from "axios-hooks"
import { toast } from 'react-toastify';
import { isArray } from "lodash-es";

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL
const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use(
  (reqConfig) => {
    let token = localStorage.getItem("accessToken");

    if (token && ! reqConfig.url.includes('login')) {
      reqConfig.headers["Authorization"] = `Bearer ${token}`;
    }
    return reqConfig;
  },
  (err) => Promise.reject(err)
);


let isRefreshing = false;
let requests = [];

axiosApi.interceptors.response.use(
  response => response,
  error => {

    // if Cancel the instance
    if (error instanceof Cancel) {
      return Promise.reject(error)
    }

    // if Network error
    if (!error.response) {

      // add axios-mock-adapter ignore
      if ( !isArray( error ) ) {
        toast.error("网络出错!")
      }
      return Promise.reject(error);

    }

    if (error.response.status === 500) {

      // if not production
      if (process.env.NODE_ENV !== "production") {
        toast.error( error.response.data.message )
      }
      return Promise.reject(error);

    }

    if (
      error.response.status === 401 &&
      error.response.data.message !== "Token has expired" &&
      !window.location.pathname.includes("/auth/login") &&
      !window.location.pathname.includes("/auth/bind")
    ) {
      window.location.href = "/auth/login"
    }

    let { config } = error.response

    if (
      error.response.status === 401 &&
      error.response.data.message === "Token has expired"
    ) {
      if (!isRefreshing) {
        isRefreshing = true
        return refreshToken()
          .then(res => {
            const { authorization: token } = res.headers
            setToken(token.replace(/Bearer /, ""))
            config.headers["Authorization"] = token
            requests.forEach(cb => cb(token))
            requests = []
            return instance(config)
          })
          .catch(err => {
            console.error("referrh token error =>", err)
            return Promise.reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      } else {
        return new Promise(resolve => {
          requests.push(token => {
            config.headers["Authorization"] = `Bearer ${token}`
            resolve(instance(config))
          })
        })
      }
    }
    return Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, (config = {})).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return await axiosApi
    .post(url, data, (config = {}))
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return await axiosApi
    .put(url, data, (config = {}))
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, (config = {}))
    .then(response => response.data)
}

export function initUseAxios() {
  configure({ axios: axiosApi })
}

export { axiosApi }

export default axiosApi