
const INIT_STATE = {
  fetchWithFake: false
}

const Settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'FETCH_WITH_FAKE':
      return {
        ...state,
        fetchWithFake: true,
      }


    default:
      return state
  }
}

export default Settings