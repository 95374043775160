import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {

        if ( !items[i].getAttribute('match-item') ) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i]
            break
          }
        } else {
          if ( items[i].getAttribute('match-item').split(",").filter(item => new RegExp(item,'i').test(pathName)).length ) {
            matchingMenuItem = items[i]
            break
          }
        }


      }

      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">


            <li className="menu-title">基金会模块</li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-news"></i>
                <span>新闻管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/manage/news" match-item={['/manage/news' , '/manage/news/add' , '/manage/news/edit/\d+']}>新闻列表</Link>
                </li>
              </ul>
            </li>



            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>项目信息管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/manage/projects" match-item={['/manage/projects' , '/manage/projects/add' , '/manage/projects/edit/\d+']}>项目信息列表</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-chip"></i>
                <span>政策法规管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/manage/policies" match-item={['/manage/policies' , '/manage/policies/add' , '/manage/policies/edit/\d+']}>政策法规列表</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-mail-send"></i>
                <span>信息公示管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/manage/disclosure-infos/milestone" match-item={['/manage/disclosure-infos/milestone' , '/manage/disclosure-infos/milestone/add' , '/manage/disclosure-infos/milestone/edit/\d+']}>项目进展列表</Link>
                </li>
              </ul>
            </li>


            <li className="menu-title">心律学会模块</li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-desktop"></i>
                <span>会议管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link  to="/manage/activities"
                    match-item={[
                      "/manage/activities",
                      "/manage/activities/add",
                      "/manage/activities/edit/\\d+",
                      "/manage/activities/\\d+/subscribers",
                      // all supscripters
                    ]}>活动列表</Link>
                </li>
              </ul>
            </li>


            <li>
              <Link to="/#" className="has-arrow ">
                <i className="mdi mdi-hospital-building"></i>
                <span>患教管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/manage/patient-learning" match-item={['/manage/patient-learning' , '/manage/patient-learning/add' , '/manage/patient-learning/edit/\d+']}>患者教育</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="mdi mdi-cast-education"></i>
                <span>教育管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/manage/education-training" match-item={['/manage/education-training' , '/manage/education-training/add' , '/manage/education-training/edit/\d+']}>培训管理</Link>
                  </li>
                  <li>
                  <Link to="/manage/education-fellowship" match-item={['/manage/education-fellowship' , '/manage/education-fellowship/add' , '/manage/education-fellowship/edit/\d+']}>进修管理</Link>
                  </li>
                  <li>
                  <Link to="/manage/education-e_library" match-item={['/manage/education-e_library' , '/manage/education-e_library/add' , '/manage/education-e_library/edit/\d+']}>E-Library管理</Link>
                </li>
              </ul>
            </li>


            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-user-circle"></i>
                <span>用户管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/manage/users" match-item={[
                    "/manage/users",
                    "/manage/users/edit/\\d+",
                  ]}>用户列表</Link>
                </li>
              </ul>
            </li>


          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
