import React, {
  Suspense,
  useState,
  useContext,
  useMemo,
  useEffect,
  createContext,
} from "react"

import { Switch, BrowserRouter as Router, useLocation, Redirect } from "react-router-dom"

// Toaster
import { ToastContainer } from 'react-toastify';

import Progress from "react-progress"

// Import Routes all
import { homeRoutes, publicRoutes, authRoutes, manageRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import UserCenterLayout from "./components/UserCenterLayout/"
import ManageCenterLayout from "./components/ManageCenterLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"


import fakeBackend from "./helpers/AuthType/fakeBackend"
import { initUseAxios } from "helpers/api_helper"

import { useDispatch } from 'react-redux'
import { fetchProfile } from "store/actions";

// Add loading bar
const IndicatorContext = createContext()

// eslint-disable-next-line react/prop-types
function IndicatorProvider({ children }) {
  const [active, setActive] = useState(false)
  const value = useMemo(() => ({ active, setActive }), [active, setActive])
  return (
    <IndicatorContext.Provider value={value}>
      {children}
    </IndicatorContext.Provider>
  )
}

function Indicator() {
  const { active } = useContext(IndicatorContext)
  const [percent, setPercent] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      setPercent(percent => (percent < 100 ? percent + 10 : 100))
    }, 200)
  })
  return active ? <Progress percent={percent} /> : null
}

function IndicatorFallback() {
  const { setActive } = useContext(IndicatorContext)
  useEffect(() => {
    setActive(true)
    return () => setActive(false)
  })
  return null
}

const App = () => {

  const location = useLocation();
  const dispatch = useDispatch();

  // Activating fake backend
  process.env.REACT_APP_DEFAULTAUTH === 'fake' && fakeBackend()

  // Init
  initUseAxios()

  // Init user if needed
  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      !location.pathname.startsWith("/auth")
    ) {
      dispatch(fetchProfile())
    }
  }, [localStorage.getItem("accessToken"), location])

  return (
    <React.Fragment>
      <IndicatorProvider>
        <Indicator />
        <ToastContainer
          position="top-right"
          limit={3}
        />
        <Suspense fallback={<IndicatorFallback />}>
          <Router>
            <Switch>

            <Redirect exact from="/" to="/manage" />

              {publicRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))}

              {authRoutes.map((route, idx) => (
                <Authmiddleware
                  path={"/auth" + route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))}

                <Authmiddleware
                  path={"/user"}
                  layout={UserCenterLayout}
                  isAuthProtected={true}
                />

              {manageRoutes.map((route, idx) => (
                <Authmiddleware
                  path={"/manage" + route.path}
                  layout={ManageCenterLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}

              {homeRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))}
            </Switch>
          </Router>
        </Suspense>
      </IndicatorProvider>
    </React.Fragment>
  )
}

export default App;