import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  FETCH_PROFILE,
} from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess , fetchProfileSuccess, fetchProfile as fetchProfileAction } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper"
import {
  postLogin,
  postSocialLogin,
  getProfile,
} from "../../helpers/backend_helper"

import { toast } from "react-toastify"
import { isArray, isNumber } from "underscore"

const fireBaseBackend = getFirebaseBackend()

function formatError(error) {
  return {
    status: isArray(error)
      ? isNumber(error[0])
        ? error[0]
        : 500
      : error.response.status,
    message: isArray(error)
      ? isNumber(error[0])
        ? error[1]
        : error[0]
      : error.response.data.message,
  }
}

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(fetchProfileAction())
      yield put(loginSuccess(response))
    } else if (
      process.env.REACT_APP_DEFAULTAUTH === "jwt" ||
      process.env.REACT_APP_DEFAULTAUTH === "fake"
    ) {
      const response = yield call(postLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("accessToken", response.access_token)
      toast.success("登录成功。")
      yield put(fetchProfileAction())
      yield put(loginSuccess(response))
    }

    history.push("/")
  } catch (error) {
    yield put(apiError( formatError( error ) ))
  }
}

function* fetchProfile() {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      // get user profile
    } else if (
      process.env.REACT_APP_DEFAULTAUTH === "jwt" ||
      process.env.REACT_APP_DEFAULTAUTH === "fake"
    ) {
      const response = yield call(getProfile)
      yield put(fetchProfileSuccess(response))
    }
  } catch (error) {

    let errors = formatError( error )

    if ( errors.status === 401 && errors.message === 'Unauthorised.' ) {
      localStorage.removeItem("authUser")
      localStorage.removeItem("accessToken")
    }
    yield put(apiError( formatError( error ) ))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("accessToken")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    toast.success("退出成功。")
    history.push("/auth/login")
  } catch (error) {
    yield put(apiError( formatError( error ) ))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError( formatError( error ) ))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(FETCH_PROFILE, fetchProfile)

  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
