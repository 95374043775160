import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import AppConfig from "../../constants/AppConfig"
import { useSelector } from "react-redux"

import classnames from 'classnames'

const Sidebar = props => {

  const { fetchWithFake } = useSelector(state => state.settings)

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <div className="d-flex align-items-center">
              <span
                className={classnames(
                  "logo-sm",
                  {
                    "ms-n4 ps-1": fetchWithFake,
                  },
                  {
                    "ms-n2": !fetchWithFake,
                  }
                )}
              >
                <img src={AppConfig.logo.logoSmDark} alt="" height="36" />
              </span>
              <span className="logo-lg">
                <img src={AppConfig.logo.logoDark} alt="" height="36" />
              </span>
              {fetchWithFake && (
                <span className="mdi mdi-progress-wrench ms-2 font-size-22 mt-n3">
                  {" "}
                </span>
              )}
            </div>
          </Link>

          <Link to="/" className="logo logo-light">
            <div className="d-flex align-items-center">
            <span
                className={classnames(
                  "logo-sm",
                  {
                    "ms-n4 ps-1": fetchWithFake,
                  },
                  {
                    "ms-n2": !fetchWithFake,
                  }
                )}
              >
                <img src={AppConfig.logo.logoSmLight} alt="" height="36" />
              </span>
              <span className="logo-lg">
                <img src={AppConfig.logo.logoLight} alt="" height="36" />
              </span>
              {fetchWithFake && (
                <span className="mdi mdi-progress-wrench ms-2 font-size-22 mt-n3 text-white">
                  {" "}
                </span>
              )}
            </div>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
