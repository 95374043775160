import usFlag from "../assets/images/flags/united.png"
import zhCNFlag from "../assets/images/flags/china.png"

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  cn: {
    label: "中文",
    flag: zhCNFlag,
  },
}

export default languages
