import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import {
  Card
} from "reactstrap"

const LeftSidebar = () => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <React.Fragment>
      <Card className="w-100">
        <div className="pt-3">
          <div className="d-flex flex-column">
            <ul className="list-unstyled sidebar-ul-list">
              <li>
                <NavLink
                  to="/user/orders"
                  className="text-body ps-4 d-flex align-items-center "
                >
                  <i className=" ms-2 mdi mdi-cart-variant font-size-16  me-3"></i>
                  <span className="me-auto">我的订单</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/user/wallet"
                  className="text-body ps-4 d-flex align-items-center"
                >
                  <i className=" ms-2 mdi mdi-wallet-outline font-size-16 me-3  "></i>
                  <span className="me-auto">我的钱包</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/user/earnings"
                  className="text-body ps-4 d-flex align-items-center"
                >
                  <i className=" ms-2 mdi mdi-cash-100 font-size-16 me-3 "></i>
                  <span className="me-auto">我的佣金</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/user/comments"
                  className="text-body ps-4 d-flex align-items-center"
                >
                  <i className=" ms-2 mdi mdi-comment-text-multiple-outline font-size-16 me-3  "></i>
                  <span className="me-auto">我的评论</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/user/favourites"
                  className="text-body ps-4 d-flex align-items-center"
                >
                  <i className=" ms-2 mdi mdi-heart-box-outline font-size-16 me-3"></i>
                  <span className="me-auto">我的收藏</span>

                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/user/messages"
                  className="text-body ps-4 d-flex align-items-center"
                >
                  <i className=" ms-2 mdi mdi-bell-outline  font-size-16 me-3"></i>
                  <span className="me-auto">消息中心</span>
                  <i className=" ms-2 mdi mdi-circle-medium  ms-2"></i>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/user/profile"
                  className="text-body ps-4 d-flex align-items-center"
                >
                  <i className=" ms-2 mdi mdi-head-cog-outline  font-size-16 me-3"></i>
                  <span className="me-auto">个人设置</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default LeftSidebar
