import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { Link, useHistory } from "react-router-dom"
import { logoutUser } from "store/auth/actions"
import { useSelector, useDispatch } from "react-redux"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const { user, loading } = useSelector(state => state.authUser)
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <React.Fragment>
      {user && (
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className={`btn header-item ${props.dropdownToggleClass}`}
            id="page-header-user-dropdown"
            tag="button"
          >
            {user.avatar ? (
              <img
                className="rounded-circle header-profile-user"
                src={user.avatar.original_url}
                alt=""
              />
            ) : (
              <span className=" avatar-xs align-middle bg-primary text-white font-size-16 d-inline-block rounded-circle  header-profile-user">
                {user.username.charAt(0)}
              </span>
            )}

            <span className="d-none d-xl-inline-block ms-2 me-1">
              {user.username}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <ul className="profilebar-ul-list list-unstyled m-0">

              <li className=" py-1 px-3">
                <Link
                  to="/manage"
                  className="btn btn-primary  btn-label btn-sm w-100"
                >
                  <i className="mdi mdi-account-reactivate-outline label-icon"></i>{" "}
                  管理平台首页
                </Link>
              </li>
            </ul>

            <div className="dropdown-divider" />
            <button
              to="/logout"
              onClick={() => dispatch(logoutUser(history))}
              className="dropdown-item"
            >
              <i className="bx bx-power-off font-size-16 align-middle me-3 text-danger" />
              <span>{props.t("Logout")}</span>
            </button>
          </DropdownMenu>
        </Dropdown>
      )}
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  dropdownToggleClass: PropTypes.any,
  t: PropTypes.any,
}

ProfileMenu.defaultProps = {
  dropdownToggleClass: "",
}

export default withTranslation()(ProfileMenu)
