import React from "react"

const Dashboard = React.lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ "../pages/Manage/Dashboard/index")
)

const NewsEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "NewsEdit" */ "../pages/Manage/News/Edit"
  )
)
const NewsAdd = React.lazy(() =>
  import(
    /* webpackChunkName: "NewsAdd" */ "../pages/Manage/News/Add"
  )
)
const NewsList = React.lazy(() =>
  import(
    /* webpackChunkName: "NewsList" */ "../pages/Manage/News/List"
  )
)

const ProjectsEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "ProjectsEdit" */ "../pages/Manage/Projects/Edit"
  )
)
const ProjectsAdd = React.lazy(() =>
  import(
    /* webpackChunkName: "ProjectsAdd" */ "../pages/Manage/Projects/Add"
  )
)

const ProjectsList = React.lazy(() =>
  import(
    /* webpackChunkName: "ProjectsList" */ "../pages/Manage/Projects/List"
  )
)

const ActivitiesSubscribers = React.lazy(() =>
  import(
    /* webpackChunkName: "ActivitiesSubscribers" */ "../pages/Manage/Activities/ActivitiesSubscribers"
  )
)

const ActivitiesEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "ActivitiesEdit" */ "../pages/Manage/Activities/ActivitiesEdit"
  )
)


const ActivitiesAdd = React.lazy(() =>
  import(
    /* webpackChunkName: "ActivitiesAdd" */ "../pages/Manage/Activities/ActivitiesAdd"
  )
)

const ActivitiesList = React.lazy(() =>
  import(
    /* webpackChunkName: "ActivitiesList" */ "../pages/Manage/Activities/ActivitiesList"
  )
)




const PatientLearningEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "PatientLearningEdit" */ "../pages/Manage/Patient/Learning/Edit"
  )
)
const PatientLearningAdd = React.lazy(() =>
  import(
    /* webpackChunkName: "PatientLearningAdd" */ "../pages/Manage/Patient/Learning/Add"
  )
)
const PatientLearningList = React.lazy(() =>
  import(
    /* webpackChunkName: "PatientLearningList" */ "../pages/Manage/Patient/Learning/List"
  )
)


const EducationTrainingEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "EducationTrainingEdit" */ "../pages/Manage/Education/Training/Edit"
  )
)
const EducationTrainingAdd = React.lazy(() =>
  import(
    /* webpackChunkName: "EducationTrainingAdd" */ "../pages/Manage/Education/Training/Add"
  )
)
const EducationTrainingList = React.lazy(() =>
  import(
    /* webpackChunkName: "EducationTrainingList" */ "../pages/Manage/Education/Training/List"
  )
)


const EducationElibraryEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "EducationElibraryEdit" */ "../pages/Manage/Education/Elibrary/Edit"
  )
)
const EducationElibraryAdd = React.lazy(() =>
  import(
    /* webpackChunkName: "EducationElibraryAdd" */ "../pages/Manage/Education/Elibrary/Add"
  )
)
const EducationElibraryList = React.lazy(() =>
  import(
    /* webpackChunkName: "EducationElibraryList" */ "../pages/Manage/Education/Elibrary/List"
  )
)

const DisclosureInfosMilestoneEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "DisclosureInfosMilestoneEdit" */ "../pages/Manage/DisclosureInfos/Milestone/Edit"
  )
)
const DisclosureInfosMilestoneAdd = React.lazy(() =>
  import(
    /* webpackChunkName: "DisclosureInfosMilestoneAdd" */ "../pages/Manage/DisclosureInfos/Milestone/Add"
  )
)
const DisclosureInfosMilestoneList = React.lazy(() =>
  import(
    /* webpackChunkName: "DisclosureInfosMilestoneList" */ "../pages/Manage/DisclosureInfos/Milestone/List"
  )
)


const WaitingList = React.lazy(() =>
  import(
    /* webpackChunkName: "WaitingList" */ "../pages/Manage/Waiting/WaitingList"
  )
)

const UserEdit = React.lazy(() =>
  import(/* webpackChunkName: "UserEdit" */ "../pages/Manage/Users/UserEdit")
)

const UserList = React.lazy(() =>
  import(/* webpackChunkName: "UserList" */ "../pages/Manage/Users/UserList")
)

const PoliciesEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "PoliciesEdit" */ "../pages/Manage/Policies/Edit"
  )
)
const PoliciesAdd = React.lazy(() =>
  import(
    /* webpackChunkName: "PoliciesAdd" */ "../pages/Manage/Policies/Add"
  )
)
const PoliciesList = React.lazy(() =>
  import(
    /* webpackChunkName: "PoliciesList" */ "../pages/Manage/Policies/List"
  )
)

export const manageRoutes = [

  { path: "/news/edit/:id", component: NewsEdit },
  { path: "/news/add", component: NewsAdd },
  { path: "/news", component: NewsList },

  { path: "/activities/:id/subscribers", component: ActivitiesSubscribers },
  { path: "/activities/edit/:id", component: ActivitiesEdit },
  { path: "/activities/add", component: ActivitiesAdd },
  { path: "/activities", component: ActivitiesList },

  { path: "/patient-learning/edit/:id", component: PatientLearningEdit },
  { path: "/patient-learning/add", component: PatientLearningAdd },
  { path: "/patient-learning", component: PatientLearningList },


  { path: "/disclosure-infos/milestone/edit/:id", component: DisclosureInfosMilestoneEdit },
  { path: "/disclosure-infos/milestone/add", component: DisclosureInfosMilestoneAdd },
  { path: "/disclosure-infos/milestone", component: DisclosureInfosMilestoneList },

  { path: "/education-training/edit/:id", component: EducationTrainingEdit },
  { path: "/education-training/add", component: EducationTrainingAdd },
  { path: "/education-training", component: EducationTrainingList },

  { path: "/education-fellowship", component: WaitingList  },

  { path: "/education-e_library/edit/:id", component: EducationElibraryEdit },
  { path: "/education-e_library/add", component: EducationElibraryAdd },
  { path: "/education-e_library", component: EducationElibraryList },


  { path: "/users/edit/:id", component: UserEdit },
  { path: "/users", component: UserList },

  // 政策法规
  { path: "/policies/edit/:id", component: PoliciesEdit },
  { path: "/policies/add", component: PoliciesAdd },
  { path: "/policies", component: PoliciesList },


  { path: "/projects/edit/:id", component: ProjectsEdit },
  { path: "/projects/add", component: ProjectsAdd },
  { path: "/projects", component: ProjectsList },


  { path: "/announcements", component: WaitingList },


  { path: "/dashboard", component: Dashboard },
  { path: "/", component: Dashboard },
]
